import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import Header from "../ui/Header"
import CarDetail from "../ui/CarDetail"
import CarGallery from "../ui/CarGallery"
import CarSpecs from "../ui/CarSpecs"
import CarDescription from "../ui/CarDescription"
import SellerContact from "../ui/SellerContact"
import FinancingSection from "../ui/FinancingSection"
import SimilarVehicles from "../ui/SimilarVehicles"
import WhyBuyWithUs from "../ui/WhyBuyWithUs"
import { FaArrowLeft, FaGem } from "react-icons/fa"
import { getDealcarStock } from "../../services/dealcarService"

const DetailVeh = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      try {
        setIsLoading(true);
        const response = await getDealcarStock();
        let vehicleData = null;

        if (response && response.vehicles) {
          vehicleData = response.vehicles.find(v => v.vehicleId === id);
        } else if (response && response.data && response.data.vehicles) {
          vehicleData = response.data.vehicles.find(v => v.vehicleId === id);
        } else if (Array.isArray(response)) {
          vehicleData = response.find(v => v.vehicleId === id);
        }

        if (vehicleData) {
          setVehicle(vehicleData);
        } else {
          setError("Vehículo no encontrado");
        }
      } catch (err) {
        setError("Error al cargar los detalles del vehículo");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchVehicleDetails();
    }
  }, [id]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#0c0c0c] text-white flex flex-col items-center justify-center gap-4">
        <div className="relative">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red-500"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <FaGem className="text-red-500 text-3xl" />
          </div>
        </div>
        <h2 className="text-2xl font-bold">Accarvip</h2>
      </div>
    );
  }

  if (error || !vehicle) {
    return (
      <div className="min-h-screen bg-[#0c0c0c] text-white flex flex-col items-center justify-center">
        <p className="text-xl text-red-500 mb-4">{error || "Vehículo no encontrado"}</p>
        <button
          onClick={() => navigate("/")}
          className="flex items-center gap-2 bg-red-500 hover:bg-red-600 text-white px-6 py-3 rounded-xl transition-colors"
        >
          <FaArrowLeft />
          Volver al inicio
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0c0c0c] text-white">
      <Header />

      <main className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => navigate("/")}
            className="flex items-center gap-2 text-white hover:text-red-500 transition-colors"
          >
            <FaArrowLeft />
            <span>Volver</span>
          </button>
        </div>

        <div className="flex flex-col lg:flex-row gap-6">
          <div className="lg:w-2/3">
            <div className="breadcrumbs text-sm mb-4">
              <ul className="flex gap-2">
                <li className="hover:text-red-500 cursor-pointer" onClick={() => navigate("/")}>Inicio</li>
                <li>/</li>
                <li className="hover:text-red-500 cursor-pointer">Vehículos</li>
                <li>/</li>
                <li className="hover:text-red-500 cursor-pointer">{vehicle.make}</li>
                <li>/</li>
                <li className="text-red-500">{vehicle.model} {vehicle.version}</li>
              </ul>
            </div>

            <CarGallery images={vehicle.multimediaList} />

            <div className="mt-8">
              <CarDetail vehicle={vehicle} />
            </div>

            <div className="mt-6">
              <CarSpecs vehicle={vehicle} />
            </div>

            <div className="mt-6">
              <CarDescription description={vehicle.description} />
            </div>
          </div>

          <div className="lg:w-1/3">
            <SellerContact vehicle={vehicle} />

            <div className="mt-6">
              <FinancingSection pricing={vehicle.pricing} />
            </div>

            <div className="mt-6">
              <SimilarVehicles 
                make={vehicle.make} 
                model={vehicle.model} 
                currentVehicleId={vehicle.vehicleId} 
              />
            </div>

            <div className="mt-6">
              <WhyBuyWithUs />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DetailVeh;
