import { supabase } from "../supabaseClient";
import {
  FaSpinner,
  FaPaperclip,
  FaTimes,
  FaHeart,
  FaShare,
  FaSearch,
  FaCalendarAlt,
  FaTag,
  FaCar,
  FaDollarSign,
  FaBolt,
  FaGasPump,
  FaRoad,
  FaEnvelope,
  FaExclamation,
  FaFireAlt,
  FaFilter,
  FaUsers,
  FaCog,
  FaShieldAlt,
  FaImage,
} from "react-icons/fa";
import {
  FiSearch,
  FiHeart as FiHeartOutline,
  FiUser,
  FiArrowUp,
  FiMenu,
  FiX,
  FiChevronDown,
} from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import ChatbotButton from "./ChatbotButton";
import { parseUserInput, getCarInfo } from "../utils/searchUtils";
import { getDealcarStock } from "../services/dealcarService";
import DetailVeh from "./DetailVeh/DetailVeh";
import { useNavigate } from "react-router-dom";
import Noimagen from "../Noimagen.jpg";
import { useState, useCallback, useEffect, useRef } from "react";

const SearchComponent = () => {
  const navigate = useNavigate();

  // Estados Principales
  const [vehicles, setVehicles] = useState([]);
  const [query, setQuery] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [likedCars, setLikedCars] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [selectedCar, setSelectedCar] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState("inicio");
  const [scrollPosition, setScrollPosition] = useState(0);

  const fileInputRef = useRef(null);
  const modelInputRef = useRef(null);
  const dropdownRef = useRef(null);

  // Estados para la paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 20;

  // Estado para determinar si es móvil
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  // Estado para mantener el último modelo consultado
  const [lastModel, setLastModel] = useState(null);

  // Estados para datos disponibles
  const [availableModels, setAvailableModels] = useState([]);
  const [availableBrands, setAvailableBrands] = useState([]);

  // Estado para filtros avanzados
  const [advancedFilters, setAdvancedFilters] = useState({
    maxPrice: "",
    minYear: "",
    maxYear: "",
    combustible: "",
    sortOrder: "asc",
    minKilometraje: "",
    maxKilometraje: "",
    model: "",
    brand: "",
  });

  const [suggestions, setSuggestions] = useState([]);

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);

  const frequentSearches = [
    "Porsche 911",
    "Tesla Model S",
    "Ferrari California",
    "BMW M3",
  ];

  const [allVehicles, setAllVehicles] = useState([]);
  const [error, setError] = useState("");

  const [searchTimeout, setSearchTimeout] = useState(null);

  const fetchVehicles = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await getDealcarStock();

      if (response && response.vehicles) {
        handleVehiclesData(response.vehicles);
      } else if (response && response.data && response.data.vehicles) {
        handleVehiclesData(response.data.vehicles);
      } else if (response && Array.isArray(response)) {
        handleVehiclesData(response);
      } else {
       
        setError("No se pudieron cargar los vehículos.");
        setAllVehicles([]);
        setVehicles([]);
      }
    } catch (error) {
      console.error("Error completo al obtener vehículos:", error);
      setAllVehicles([]);
      setVehicles([]);
      setTotalPages(1);
      setError("Error al cargar los vehículos. Por favor, intente nuevamente.");
    }

    setIsLoading(false);
  }, [pageSize]);

  const handleVehiclesData = useCallback(
    (vehiclesData) => {
      if (Array.isArray(vehiclesData) && vehiclesData.length > 0) {
        const sortedVehicles = [...vehiclesData].sort(
          (a, b) => a.pricing.price - b.pricing.price
        );

        setAllVehicles(sortedVehicles);
        setVehicles(sortedVehicles);
        setTotalPages(Math.ceil(sortedVehicles.length / pageSize));
        setError("");
      } else {
        setError("No hay vehículos disponibles en este momento.");
        setAllVehicles([]);
        setVehicles([]);
        setTotalPages(1);
      }
    },
    [pageSize]
  );

  useEffect(() => {
    fetchVehicles();

    const channel = supabase
      .channel("custom-all-channel")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "VEHÍCULOS2_duplicate con datos",
        },
        (payload) => {
          console.log("Cambios detectados en la base de datos:", payload);
          fetchVehicles();
        }
      )
      .subscribe();

    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        modelInputRef.current &&
        !modelInputRef.current.contains(event.target)
      ) {
        setIsModelDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      channel.unsubscribe();
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [fetchVehicles]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Cargar la lista de modelos
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await getDealcarStock();
        let vehiclesData = [];

        if (response && response.vehicles) {
          vehiclesData = response.vehicles;
        } else if (response && response.data && response.data.vehicles) {
          vehiclesData = response.data.vehicles;
        } else if (Array.isArray(response)) {
          vehiclesData = response;
        }

        if (vehiclesData.length > 0) {
          const uniqueModels = [
            ...new Set(
              vehiclesData
                .map((vehicle) =>
                  vehicle.model ? vehicle.model.toLowerCase() : ""
                )
                .filter(Boolean)
            ),
          ];
          uniqueModels.sort((a, b) => a.localeCompare(b));
          setAvailableModels(uniqueModels);
        }
      } catch (error) {
        console.error("Error al obtener modelos:", error);
        setAvailableModels([]);
      }
    };

    fetchModels();
  }, []);

  // Cargar la lista de marcas
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await getDealcarStock();
        let vehiclesData = [];

        if (response && response.vehicles) {
          vehiclesData = response.vehicles;
        } else if (response && response.data && response.data.vehicles) {
          vehiclesData = response.data.vehicles;
        } else if (Array.isArray(response)) {
          vehiclesData = response;
        }

        if (vehiclesData.length > 0) {
          const uniqueBrands = [
            ...new Set(
              vehiclesData
                .map((vehicle) =>
                  vehicle.make ? vehicle.make.toLowerCase() : ""
                )
                .filter(Boolean)
            ),
          ];
          uniqueBrands.sort((a, b) => a.localeCompare(b));
          setAvailableBrands(uniqueBrands);
        }
      } catch (error) {
        console.error("Error al obtener marcas:", error);
        setAvailableBrands([]);
      }
    };

    fetchBrands();
  }, []);

  // Manejar Subida de Archivos
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const newUploadedFiles = files.map((file) => ({
      id: Math.random().toString(36).substr(2, 9),
      name: file.name,
      type: file.type,
      size: file.size,
      preview: file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : null,
    }));
    setUploadedFiles((prev) => [...prev, ...newUploadedFiles]);
  };

  // Eliminar Archivo Subido
  const removeFile = (fileId) => {
    setUploadedFiles((prev) => prev.filter((file) => file.id !== fileId));
  };

  const FilePreview = () => (
    <AnimatePresence>
      {uploadedFiles.length > 0 && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="mt-4 p-4 bg-gray-800/50 rounded-xl backdrop-blur-xl border border-gray-700/50"
        >
          <div className="flex flex-wrap gap-4">
            {uploadedFiles.map((file) => (
              <div key={file.id} className="relative group">
                {file.preview ? (
                  <div className="relative w-24 h-24 rounded-lg overflow-hidden">
                    <img
                      src={file.preview}
                      alt={file.name}
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                    <button
                      onClick={() => removeFile(file.id)}
                      className="absolute top-1 right-1 p-1 bg-red-500 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                      aria-label={`Eliminar archivo ${file.name}`}
                    >
                      <FaTimes className="text-white text-sm" />
                    </button>
                  </div>
                ) : (
                  <div className="w-24 h-24 rounded-lg bg-gray-700 flex items-center justify-center relative">
                    <FaCar className="text-2xl text-gray-400" />
                    <button
                      onClick={() => removeFile(file.id)}
                      className="absolute top-1 right-1 p-1 bg-red-500 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                      aria-label={`Eliminar archivo ${file.name}`}
                    >
                      <FaTimes className="text-white text-sm" />
                    </button>
                  </div>
                )}
                <p className="mt-1 text-xs text-gray-400 truncate max-w-[96px]">
                  {file.name}
                </p>
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  // Manejar "Me gusta" en Vehículos
  const handleLike = (carId) => {
    setLikedCars((prev) =>
      prev.includes(carId)
        ? prev.filter((id) => id !== carId)
        : [...prev, carId]
    );
  };

  // Manejar Compartir Vehículo
  const handleShare = (car) => {
    const url = `${window.location.href}?car=${encodeURIComponent(car.model)}`;
    setShareUrl(url);
    setShowShareModal(true);
  };

  // Copiar Enlace
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      alert("¡Enlace copiado al portapapeles!");
      setShowShareModal(false);
    } catch (err) {
      alert("Error al copiar el enlace");
    }
  };

  // Solicitar Cotización
  const handleSolicitarCotizacion = (car) => {
    if (car && car.dealcarLink) {
      window.open(car.dealcarLink, "_blank");
    } else {
      alert("Enlace no disponible para este vehículo.");
    }
  };

  // Funciones para paginación
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // Función para renderizar la paginación
  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const maxPageButtons = isMobile ? 4 : 7;

    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = startPage + maxPageButtons - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex flex-wrap items-center justify-center mt-8 mb-8 space-x-2">
        <button
          onClick={() => handlePreviousPage()}
          disabled={currentPage === 1}
          className={`px-3 py-2 rounded-lg text-sm md:text-base ${
            currentPage === 1
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-gray-800 hover:bg-gray-700"
          } text-white transition-colors duration-300`}
          aria-label="Página Anterior"
        >
          Anterior
        </button>

        {startPage > 1 && (
          <>
            <button
              onClick={() => setCurrentPage(1)}
              className="px-3 py-2 rounded-lg text-sm md:text-base bg-gray-800 hover:bg-gray-700 text-white transition-colors duration-300"
              aria-label="Página 1"
            >
              1
            </button>
            {startPage > 2 && <span className="text-white px-2">...</span>}
          </>
        )}

        {pageNumbers.map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={`px-3 py-2 rounded-lg text-sm md:text-base ${
              currentPage === page
                ? "bg-red-600 cursor-default"
                : "bg-gray-800 hover:bg-gray-700"
            } text-white transition-colors duration-300`}
            aria-label={`Página ${page}`}
            disabled={currentPage === page}
          >
            {page}
          </button>
        ))}

        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && (
              <span className="text-white px-2">...</span>
            )}
            <button
              onClick={() => setCurrentPage(totalPages)}
              className="px-3 py-2 rounded-lg text-sm md:text-base bg-gray-800 hover:bg-gray-700 text-white transition-colors duration-300"
              aria-label={`Página ${totalPages}`}
            >
              {totalPages}
            </button>
          </>
        )}

        <button
          onClick={() => handleNextPage()}
          disabled={currentPage === totalPages}
          className={`px-3 py-2 rounded-lg text-sm md:text-base ${
            currentPage === totalPages
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-gray-800 hover:bg-gray-700"
          } text-white transition-colors duration-300`}
          aria-label="Página Siguiente"
        >
          Siguiente
        </button>
      </div>
    );
  };

  // Renderizar Panel de Filtros Avanzados
  const renderAdvancedSearch = () => (
    <AnimatePresence>
      {showAdvancedSearch && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="mt-4 bg-gradient-to-r from-gray-800 via-gray-900 to-black rounded-3xl p-6 backdrop-blur-xl border border-gray-700/50 overflow-hidden shadow-lg"
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">Marca</label>
              <select
                value={advancedFilters.brand}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    brand: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                aria-label="Marca"
              >
                <option value="">Todas las marcas</option>
                {availableBrands.map((brand) => (
                  <option key={brand} value={brand}>
                    {brand.charAt(0).toUpperCase() + brand.slice(1)}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">
                Modelo
              </label>
              <select
                value={advancedFilters.model}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    model: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                aria-label="Modelo"
              >
                <option value="">Todos los modelos</option>
                {availableModels.map((model) => (
                  <option key={model} value={model}>
                    {model.charAt(0).toUpperCase() + model.slice(1)}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">
                Precio Máximo (€)
              </label>
              <input
                type="number"
                value={advancedFilters.maxPrice}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    maxPrice: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                placeholder="Ingrese el precio máximo"
                aria-label="Precio Máximo"
              />
            </div>

            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">
                Año Mínimo
              </label>
              <select
                value={advancedFilters.minYear}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    minYear: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                aria-label="Año Mínimo"
              >
                <option value="">Seleccionar año</option>
                {[...Array(21).keys()].map((i) => (
                  <option key={2024 - i} value={2024 - i}>
                    {2024 - i}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">
                Año Máximo
              </label>
              <select
                value={advancedFilters.maxYear}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    maxYear: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                aria-label="Año Máximo"
              >
                <option value="">Seleccionar año</option>
                {[...Array(21).keys()].map((i) => (
                  <option key={2024 - i} value={2024 - i}>
                    {2024 - i}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">
                Kilometraje Mínimo (km)
              </label>
              <input
                type="number"
                value={advancedFilters.minKilometraje}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    minKilometraje: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                placeholder="Kilometraje mínimo"
                aria-label="Kilometraje Mínimo"
              />
            </div>

            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">
                Kilometraje Máximo (km)
              </label>
              <input
                type="number"
                value={advancedFilters.maxKilometraje}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    maxKilometraje: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                placeholder="Kilometraje máximo"
                aria-label="Kilometraje Máximo"
              />
            </div>

            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">
                Combustible
              </label>
              <select
                value={advancedFilters.combustible}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    combustible: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                aria-label="Combustible"
              >
                <option value="">Todos</option>
                <option value="GASOLINE">Gasolina</option>
                <option value="DIESEL">Diesel</option>
                <option value="ELECTRIC">Eléctrico</option>
              </select>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  const handleFilter = (type) => {
    setIsLoading(true);
    const currentModel = advancedFilters.model;
    const currentBrand = advancedFilters.brand;

    let newFilters = {
      ...advancedFilters,
      model: currentModel,
      brand: currentBrand,
    };

    switch (type) {
      case "cheap":
        newFilters = {
          ...newFilters,
          sortOrder: "asc",
          combustible: "",
          maxPrice: "",
          minYear: "",
          maxYear: "",
          minKilometraje: "",
          maxKilometraje: "",
        };
        break;
      case "expensive":
        newFilters = {
          ...newFilters,
          sortOrder: "desc",
          combustible: "",
          maxPrice: "",
          minYear: "",
          maxYear: "",
          minKilometraje: "",
          maxKilometraje: "",
        };
        break;
      case "electric":
        newFilters = {
          ...newFilters,
          sortOrder: "asc",
          combustible: "ELECTRIC",
          maxPrice: "",
          minYear: "",
          maxYear: "",
          minKilometraje: "",
          maxKilometraje: "",
        };
        break;
      default:
        break;
    }

    setAdvancedFilters(newFilters);

    let filteredVehicles = [...allVehicles];

    // Aplicar filtros
    if (newFilters.combustible) {
      filteredVehicles = filteredVehicles.filter((vehicle) => {
        if (!vehicle || !vehicle.fuel) return false;
        const fuelType = vehicle.fuel.toString().toUpperCase();
        return fuelType === newFilters.combustible;
      });
    }

    // Aplicar ordenamiento
    filteredVehicles.sort((a, b) => {
      if (newFilters.sortOrder === "asc") {
        return a.pricing.price - b.pricing.price;
      } else {
        return b.pricing.price - a.pricing.price;
      }
    });

    setVehicles(filteredVehicles);
    setTotalPages(Math.ceil(filteredVehicles.length / pageSize));
    setIsLoading(false);
  };

  const applyFilters = useCallback(() => {
    setIsLoading(true);
    let filteredVehicles = [...allVehicles];

    const {
      maxPrice,
      minYear,
      maxYear: maxYearFilter,
      combustible,
      sortOrder,
      minKilometraje,
      maxKilometraje,
      model,
      brand,
    } = advancedFilters;

    // Aplicar filtros
    filteredVehicles = filteredVehicles.filter((vehicle) => {
      const matchesPrice = maxPrice
        ? vehicle.pricing.price <= parseInt(maxPrice)
        : true;
      const matchesFuel = combustible
        ? vehicle.fuel &&
          vehicle.fuel.toString().toUpperCase() === combustible.toUpperCase()
        : true;
      const matchesBrand = brand
        ? vehicle.make.toLowerCase().includes(brand.toLowerCase())
        : true;
      const matchesModel = model
        ? vehicle.model.toLowerCase().includes(model.toLowerCase())
        : true;
      const matchesMinYear = minYear
        ? parseInt(vehicle.registrationYear) >= parseInt(minYear)
        : true;
      const matchesMaxYear = maxYearFilter
        ? parseInt(vehicle.registrationYear) <= parseInt(maxYearFilter)
        : true;
      const matchesMinKm = minKilometraje
        ? vehicle.kilometers >= parseInt(minKilometraje)
        : true;
      const matchesMaxKm = maxKilometraje
        ? vehicle.kilometers <= parseInt(maxKilometraje)
        : true;

      return (
        matchesPrice &&
        matchesFuel &&
        matchesBrand &&
        matchesModel &&
        matchesMinYear &&
        matchesMaxYear &&
        matchesMinKm &&
        matchesMaxKm
      );
    });

    // Aplicar ordenamiento
    filteredVehicles.sort((a, b) => {
      if (sortOrder === "asc") {
        return a.pricing.price - b.pricing.price;
      } else {
        return b.pricing.price - a.pricing.price;
      }
    });

    setVehicles(filteredVehicles);
    setTotalPages(Math.ceil(filteredVehicles.length / pageSize));
    setIsLoading(false);
  }, [advancedFilters, allVehicles, pageSize]);

  // Agregar useEffect para aplicar filtros cuando cambien
  useEffect(() => {
    if (allVehicles.length > 0) {
      applyFilters();
    }
  }, [advancedFilters, applyFilters]);

  const processNaturalLanguageSearch = (query) => {
    const queryLower = query.toLowerCase();

    // Definir palabras clave para diferentes criterios
    const priceKeywords = {
      barato: "asc",
      económico: "asc",
      "más barato": "asc",
      caro: "desc",
      "más caro": "desc",
      costoso: "desc",
      lujoso: "desc",
    };

    const kmKeywords = [
      "kilometraje",
      "kilómetros",
      "km",
      "menos km",
      "bajo kilometraje",
      "mayor kilometraje",
    ];

    // Extraer criterios de búsqueda
    const searchCriteria = {
      make: "",
      model: "",
      sortByKm: false,
      priceOrder: "asc",
    };

    // Buscar marca usando availableBrands
    const words = queryLower.split(" ");
    for (const word of words) {
      if (availableBrands.includes(word.toLowerCase())) {
        searchCriteria.make = word;
        break;
      }
    }

    // Verificar si se menciona kilometraje
    if (kmKeywords.some((keyword) => queryLower.includes(keyword))) {
      searchCriteria.sortByKm = true;
    }

    // Buscar orden de precio
    for (const [keyword, order] of Object.entries(priceKeywords)) {
      if (queryLower.includes(keyword)) {
        searchCriteria.priceOrder = order;
        break;
      }
    }

    return searchCriteria;
  };

  const handleSearch = async (searchValue) => {
    setIsLoading(true);
    const queryStr = searchValue.trim().toLowerCase();
  
 

    if (!allVehicles?.length) {
      setError("No hay vehículos disponibles");
      setIsLoading(false);
      return;
    }

    // Definir términos compuestos que deben mantenerse juntos
    const compoundTerms = ['mas caro', 'más caro', 'mas barato', 'más barato'];
    
    // Primero, buscar y reemplazar términos compuestos con un marcador
    let processedQuery = queryStr;
    const replacements = new Map();
    
    compoundTerms.forEach((term, index) => {
      if (processedQuery.includes(term)) {
        const marker = `__COMPOUND_${index}__`;
        replacements.set(marker, term);
        processedQuery = processedQuery.replace(term, marker);
      }
    });

    // Ahora separar los términos y restaurar los términos compuestos
    const searchTerms = processedQuery
      .split(' ')
      .filter(term => term.length > 0)
      .map(term => {
        if (term.startsWith('__COMPOUND_')) {
          return replacements.get(term) || term;
        }
        return term;
      });



    // Verificar términos de precio
    const priceTerms = {
      'mas caro': 'desc',
      'más caro': 'desc',
      'mas barato': 'asc',
      'más barato': 'asc'
    };

    // Buscar términos de precio
    let priceOrder = null;
    for (const [term, order] of Object.entries(priceTerms)) {
      if (searchTerms.includes(term)) {
        priceOrder = order;
    
        break;
      }
    }

    // Inicializar resultados
    let searchResults = [...allVehicles];


    // Buscar marca
    const brandTerm = searchTerms.find(term => 
      availableBrands.some(brand => brand.toLowerCase() === term.toLowerCase())
    );

    // Filtrar por marca si existe
    if (brandTerm) {
      
      searchResults = searchResults.filter(vehicle => 
        vehicle.make && vehicle.make.toLowerCase() === brandTerm.toLowerCase()
      );
    }

    // Aplicar ordenamiento por precio
    if (priceOrder) {
      searchResults.sort((a, b) => {
        return priceOrder === 'desc' 
          ? b.pricing.price - a.pricing.price 
          : a.pricing.price - b.pricing.price;
      });
    }

    setVehicles(searchResults);
    setTotalPages(Math.ceil(searchResults.length / pageSize));
    setCurrentPage(1);
    setIsLoading(false);
  };

  // Modificar getPaginatedVehicles para mantener el orden
  const getPaginatedVehicles = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return vehicles.slice(startIndex, endIndex);
  };

  const hasActiveFilters = (filters) => {
    return Object.values(filters).some(
      (value) => value !== "" && value !== "asc"
    );
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setAdvancedFilters((prev) => ({
      ...prev,
      model: suggestion,
      brand: suggestion,
    }));
    handleSearch(suggestion);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    
    // Actualizar el estado del query inmediatamente
    setQuery(value);

    // Si el campo está vacío, restaurar estado inicial inmediatamente
    if (!value.trim()) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      
      setAdvancedFilters({
        maxPrice: "",
        minYear: "",
        maxYear: "",
        combustible: "",
        sortOrder: "asc",
        minKilometraje: "",
        maxKilometraje: "",
        model: "",
        brand: "",
      });

      const sortedVehicles = [...allVehicles].sort(
        (a, b) => a.pricing.price - b.pricing.price
      );
     
      setVehicles(sortedVehicles);
      setTotalPages(Math.ceil(sortedVehicles.length / pageSize));
      setCurrentPage(1);
      setError("");
      setSuggestions([]);
      setIsLoading(false);
      return;
    }

    // Limpiar timeout anterior
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Crear nuevo timeout con el valor actual
    const timeoutId = setTimeout(() => {
      // Usar una función para capturar el valor actual
      const currentValue = value;
      
      if (currentValue.trim().length > 0) {
        setIsLoading(true);
        // Pasar el valor actual directamente a handleSearch
        handleSearch(currentValue);
      } else {
        setIsLoading(false);
      }
    }, 300); // Reducir el tiempo de debounce para una respuesta más rápida

    setSearchTimeout(timeoutId);
  };

  const renderSuggestions = () => (
    <div className="mt-2 flex flex-wrap gap-2">
      {suggestions.map((search, index) => (
        <button
          key={index}
          onClick={() => handleSuggestionClick(search)}
          className="bg-gray-700 text-white px-3 py-1 rounded-full hover:bg-gray-600 transition-all"
        >
          {search}
        </button>
      ))}
    </div>
  );

  const handleViewDetails = (car) => {
    navigate(`/veh/${car.vehicleId}`);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const pages = [
    { id: "inicio", name: "Inicio" },
    { id: "vehiculos", name: "Vehículos" },
    { id: "catalogo", name: "Catálogo" },
    { id: "nosotros", name: "Nosotros" },
    { id: "contacto", name: "Contacto" },
  ];

  return (
    <div className="min-h-screen bg-black text-white relative overflow-x-hidden">
      <motion.header className="fixed w-full top-0 z-50 bg-black backdrop-blur-md transition-all duration-300 border-b border-[#202630]">
        <nav className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <motion.img
              src="/img/accarlogo.svg"
              alt="Ac Car Logo"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{ scale: 1.05 }}
              className="w-[144px] h-[23px] cursor-pointer"
              onClick={() => setActivePage("inicio")}
            />

            <div className="hidden lg:flex items-center space-x-8">
              {pages.map((page) => (
                <motion.button
                  key={page.id}
                  className={`px-4 py-2 rounded-full transition-colors duration-300 text-sm ${
                    activePage === page.id ? "bg-white/20" : "hover:bg-white/10"
                  }`}
                  onClick={() => setActivePage(page.id)}
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(255,0,0,0.3)",
                  }}
                  whileTap={{ scale: 0.95 }}
                >
                  <span>{page.name}</span>
                </motion.button>
              ))}
            </div>

            <div className="flex items-center space-x-4">
              <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <FiSearch className="text-xl cursor-pointer" />
              </motion.div>
              <div className="flex items-center space-x-4">
                <motion.div
                  whileHover={{ scale: 1.1, color: "#ff0000" }}
                  whileTap={{ scale: 0.9 }}
                >
                  <FiHeartOutline className="text-xl cursor-pointer transition-colors duration-300" />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.1, color: "#ff0000" }}
                  whileTap={{ scale: 0.9 }}
                >
                  <FiUser className="text-xl cursor-pointer transition-colors duration-300" />
                </motion.div>
              </div>
              <motion.button
                className="lg:hidden text-white"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                aria-label="Toggle Sidebar"
              >
                <FiMenu size={24} />
              </motion.button>
            </div>
          </div>
        </nav>
      </motion.header>

      <AnimatePresence>
        {isSidebarOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50"
              onClick={() => setIsSidebarOpen(false)}
            />
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ type: "spring", bounce: 0, duration: 0.4 }}
              className="fixed inset-0 bg-black z-50 p-6 flex flex-col"
            >
              <div className="flex justify-end mb-6">
                <button
                  onClick={() => setIsSidebarOpen(false)}
                  className="focus:outline-none"
                >
                  <FiX size={24} />
                </button>
              </div>
              <div className="flex flex-col space-y-8 items-center justify-center flex-grow">
                <motion.div className="relative w-full max-w-md">
                  <input
                    type="text"
                    placeholder="Buscar vehículos..."
                    className="w-full py-3 px-4 pl-10 bg-white/10 rounded-full focus:outline-none focus:ring-2 focus:ring-white/50 text-lg"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
                </motion.div>
                {pages.map((page, index) => (
                  <motion.button
                    key={page.id}
                    className={`px-6 py-4 rounded-full transition-colors duration-300 text-lg ${
                      activePage === page.id
                        ? "bg-white/20"
                        : "hover:bg-white/10"
                    } w-full max-w-md`}
                    onClick={() => {
                      setActivePage(page.id);
                      setIsSidebarOpen(false);
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <span>{page.name}</span>
                  </motion.button>
                ))}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      <div className="pt-24 px-4 sm:px-8 flex flex-col items-center justify-center">
        <motion.h1
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="text-4xl font-bold text-center text-white mb-6"
        >
          ACCAR STOCK
        </motion.h1>

        <div className="w-full max-w-5xl mb-8">
          <div className="relative backdrop-blur-xl bg-gray-800/30 rounded-3xl shadow-2xl transition-all duration-300 overflow-hidden border border-gray-700/50">
            <div className="p-4 sm:p-6">
              <div className="relative flex flex-col sm:flex-row items-center gap-4">
                <div className="hidden sm:block">
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    accept="image/*,video/*,.pdf,.doc,.docx"
                    onChange={handleFileUpload}
                    multiple
                  />
                  <button
                    onClick={() => fileInputRef.current.click()}
                    className="p-3 sm:p-4 bg-red-500/20 rounded-xl hover:bg-red-500/30 transition-colors group"
                    aria-label="Adjuntar archivo"
                  >
                    <FaPaperclip className="text-red-500 text-lg group-hover:scale-110 transition-transform" />
                  </button>
                </div>
                <div className="relative flex-1 w-full">
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => {
                      handleInputChange(e);
                      setCurrentPage(1);
                    }}
                    className="w-full px-4 sm:px-6 py-3 sm:py-4 text-gray-200 bg-gray-700/50 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 transition-all placeholder-gray-400 backdrop-blur-xl"
                    placeholder="Buscar vehículos de lujo (e.g., 'Ferrari más barato')"
                    aria-label="Campo de búsqueda"
                  />
                  <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                    {isLoading ? (
                      <FaSpinner className="animate-spin text-red-500 text-xl" />
                    ) : (
                      <FaSearch className="text-gray-400 text-xl" />
                    )}
                  </div>
                </div>
                <button
                  onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                  className="p-3 sm:p-4 bg-red-500/20 rounded-xl hover:bg-red-500/30 transition-colors group"
                  aria-label="Búsqueda avanzada"
                >
                  <FaFilter
                    className={`text-red-500 text-lg group-hover:scale-110 transition-transform ${
                      showAdvancedSearch ? "rotate-180" : ""
                    }`}
                  />
                </button>
              </div>
              {renderSuggestions()}
              <FilePreview />
              {renderAdvancedSearch()}
            </div>
          </div>

          <div className="mt-6 flex flex-wrap justify-center gap-3">
            <button
              onClick={() => handleFilter("expensive")}
              className={`flex items-center space-x-2 px-3 py-1.5 rounded-full text-white text-sm ${
                advancedFilters.sortOrder === "desc" &&
                !advancedFilters.combustible &&
                !advancedFilters.brand &&
                !advancedFilters.model
                  ? "bg-red-500"
                  : "bg-gray-800 hover:bg-gray-700"
              } transition-all shadow-lg`}
              aria-label="Filtrar Coches Más Caros"
            >
              <FaDollarSign className="text-yellow-500 text-lg" />
              <span>Coches Más Caros</span>
            </button>
            <button
              onClick={() => handleFilter("electric")}
              className={`flex items-center space-x-2 px-3 py-1.5 rounded-full text-white text-sm ${
                advancedFilters.combustible === "ELECTRIC"
                  ? "bg-blue-500"
                  : "bg-gray-800 hover:bg-gray-700"
              } transition-all shadow-lg`}
              aria-label="Filtrar Coches Eléctricos"
            >
              <FaBolt className="text-blue-500 text-lg" />
              <span>Coches Eléctricos</span>
            </button>
            <button
              onClick={() => handleFilter("cheap")}
              className={`flex items-center space-x-2 px-3 py-1.5 rounded-full text-white text-sm ${
                advancedFilters.sortOrder === "asc" &&
                !advancedFilters.combustible &&
                !advancedFilters.brand &&
                !advancedFilters.model
                  ? "bg-purple-500"
                  : "bg-gray-800 hover:bg-gray-700"
              } transition-all shadow-lg`}
              aria-label="Filtrar Coches Más Baratos"
            >
              <FaTag className="text-purple-500 text-lg" />
              <span>Coches Más Baratos</span>
            </button>
          </div>
        </div>

        {vehicles.length === 0 && !isLoading && error && (
          <p className="text-gray-400 text-lg mt-8">{error}</p>
        )}

        {isLoading && (
          <div className="flex justify-center items-center mt-8">
            <FaSpinner className="animate-spin text-red-500 text-2xl" />
            <span className="ml-2 text-gray-400 text-lg">Cargando...</span>
          </div>
        )}

        <div className="w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {getPaginatedVehicles().map((car, index) => (
            <motion.div
              key={car.vehicleId}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gray-800/50 rounded-2xl overflow-hidden border border-gray-700/50 backdrop-blur-xl hover:shadow-xl hover:shadow-red-500/10 transition-all duration-300 h-full flex flex-col"
            >
              <div className="relative group h-48">
                {car.multimediaList &&
                car.multimediaList.length > 0 &&
                car.multimediaList[0]?.url ? (
                  <img
                    src={car.multimediaList[0].url}
                    alt={car.model}
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    loading="lazy"
                  />
                ) : (
                  <div className="relative w-full h-full">
                    <img
                      src={Noimagen}
                      alt="No hay imagen disponible"
                      className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-black/50">
                      <span className="text-white text-lg font-medium">
                        Imagen no disponible
                      </span>
                    </div>
                  </div>
                )}
                <div className="absolute top-4 right-4 space-x-2">
                  <button
                    onClick={() => handleLike(car.vehicleId)}
                    className={`p-2 ${
                      likedCars.includes(car.vehicleId)
                        ? "bg-red-500"
                        : "bg-gray-900/80"
                    } rounded-full hover:bg-red-500/80 transition-colors`}
                    aria-label={`Me gusta ${car.model}`}
                  >
                    <FaHeart className="text-white text-sm" />
                  </button>
                  <button
                    onClick={() => handleShare(car)}
                    className="p-2 bg-gray-900/80 rounded-full hover:bg-blue-500/80 transition-colors"
                    aria-label={`Compartir ${car.model}`}
                  >
                    <FaShare className="text-white text-sm" />
                  </button>
                </div>
              </div>
              <div className="p-6 space-y-4 flex flex-col justify-between flex-grow">
                <div>
                  <h3 className="text-xl font-bold text-white">
                    {car.make} {car.model}
                  </h3>
                  <p className="text-lg font-bold text-red-500">
                    €{car.pricing.price}
                  </p>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center space-x-2">
                      <FaCalendarAlt className="text-red-500 text-lg" />
                      <span className="text-gray-300">
                        Año: {car.registrationYear}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <FaTag className="text-red-500 text-lg" />
                      <span className="text-gray-300">
                        Color: {car.bodyStyle || "N/A"}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-2">
                    <div className="flex items-center space-x-2">
                      <FaGasPump className="text-red-500 text-lg" />
                      <span className="text-gray-300">{car.fuel}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <FaRoad className="text-red-500 text-lg" />
                      <span className="text-gray-300">{car.kilometers} km</span>
                    </div>
                  </div>
                </div>
                <div className="pt-4 border-t border-gray-700 mt-auto space-y-2">
                  <button
                    onClick={() => handleSolicitarCotizacion(car)}
                    className="w-full bg-gradient-to-r from-red-600 to-red-800 text-white py-2 rounded-xl hover:shadow-lg hover:shadow-red-500/30 transition-all"
                  >
                    Solicitar Información
                  </button>
                  <button
                    onClick={() => handleViewDetails(car)}
                    className="w-full bg-gray-700 text-white py-2 rounded-xl hover:bg-red-600 transition-all"
                    aria-label={`Ver Detalles de ${car.model}`}
                  >
                    Ver Detalles
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {renderPagination()}

        <AnimatePresence>
          {showShareModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
            >
              <motion.div
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.95 }}
                className="bg-gray-800 rounded-2xl p-6 max-w-md w-full"
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-bold text-white">
                    Compartir Vehículo
                  </h3>
                  <button
                    onClick={() => setShowShareModal(false)}
                    className="p-2 hover:bg-gray-700 rounded-full"
                    aria-label="Cerrar Compartir"
                  >
                    <FaTimes className="text-gray-400" />
                  </button>
                </div>
                <div className="space-y-4">
                  <input
                    type="text"
                    value={shareUrl}
                    readOnly
                    className="w-full bg-gray-700 rounded-lg px-4 py-2 text-white"
                    aria-label="Enlace de Compartir"
                  />
                  <button
                    onClick={copyToClipboard}
                    className="w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white py-2 rounded-xl hover:shadow-lg hover:shadow-blue-500/30 transition-all"
                  >
                    Copiar Enlace
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <motion.div
        className={`fixed bottom-6 md:bottom-8 right-6 md:right-8 flex flex-row gap-2 z-50 ${
          isMobile && isChatbotOpen ? "hidden" : "flex"
        }`}
      >
        <motion.button
          onClick={scrollToTop}
          className="p-2 bg-blue-500 rounded-full hover:bg-blue-600 transition-colors"
          aria-label="Subir al Inicio"
        >
          <FiArrowUp className="text-white text-2xl md:text-3xl" />
        </motion.button>

        <ChatbotButton setIsChatbotOpen={setIsChatbotOpen} />
      </motion.div>
    </div>
  );
};

export default SearchComponent;
