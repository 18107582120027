import axios from "axios";

export async function getDealcarStock() {
  try {
    const response = await axios.get("https://accar-backend.onrender.com/api/stock");
    return response.data; 
  } catch (error) {
    console.error("Error al obtener datos de TU backend:", error);
    throw error;
  }
}
