import { Calendar, Droplet, Gauge, Settings } from "lucide-react"

const CarDetail = ({ vehicle }) => {
  return (
    <div className="bg-gray-800/50 rounded-2xl p-6 backdrop-blur-xl border border-gray-700/50">
      <div className="mb-6">
        <h1 className="text-3xl font-bold">{vehicle.make} {vehicle.model}</h1>
        <p className="text-gray-400">{vehicle.version}</p>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-start gap-6">
        <div className="grid grid-cols-2 gap-6">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 bg-[#1e1e1e] rounded-full flex items-center justify-center text-red-500">
              <Calendar size={18} />
            </div>
            <div>
              <div className="text-xs text-gray-400">Año</div>
              <div className="font-semibold">{vehicle.registrationYear}</div>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <div className="w-10 h-10 bg-[#1e1e1e] rounded-full flex items-center justify-center text-red-500">
              <Droplet size={18} />
            </div>
            <div>
              <div className="text-xs text-gray-400">Combustible</div>
              <div className="font-semibold">{vehicle.fuel || "No especificado"}</div>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <div className="w-10 h-10 bg-[#1e1e1e] rounded-full flex items-center justify-center text-red-500">
              <Gauge size={18} />
            </div>
            <div>
              <div className="text-xs text-gray-400">Potencia</div>
              <div className="font-semibold">{vehicle.power ? `${vehicle.power} CV` : "No especificado"}</div>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <div className="w-10 h-10 bg-[#1e1e1e] rounded-full flex items-center justify-center text-red-500">
              <Settings size={18} />
            </div>
            <div>
              <div className="text-xs text-gray-400">Transmisión</div>
              <div className="font-semibold">{vehicle.transmission || "No especificado"}</div>
            </div>
          </div>
        </div>

        <div className="bg-[#1e1e1e] border border-red-600 rounded-xl p-4 text-center w-full md:w-auto">
          <div className="text-3xl font-bold text-red-500">
            €{vehicle.pricing.price.toLocaleString()}
          </div>
          {vehicle.pricing.financing && vehicle.pricing.financing.financingPrice && (
            <div className="text-sm text-gray-400">
              Financiado: €{vehicle.pricing.financing.financingPrice.toLocaleString()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarDetail;
