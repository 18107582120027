import { Search, Heart, User } from "lucide-react"

const Header = () => {
  return (
    <header className="bg-[#111827] border-b border-gray-800 sticky top-0 z-50">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center gap-3">
          <div className="text-white font-bold text-2xl tracking-wider">
            <span>Accar</span>
            <span className="text-red-600">Vip</span>
          </div>
        </div>

        <nav className="hidden md:flex items-center space-x-8">
          <a href="/" className="text-white hover:text-red-500 font-medium">
            Inicio
          </a>
          <a href="/" className="text-white hover:text-red-500 font-medium">
            Vehículos
          </a>
          <a href="#" className="text-white hover:text-red-500 font-medium">
            Catálogo
          </a>
          <a href="#" className="text-white hover:text-red-500 font-medium">
            Nosotros
          </a>
          <a href="#" className="text-white hover:text-red-500 font-medium">
            Contacto
          </a>
        </nav>

        <div className="flex items-center gap-4">
          <button className="text-white hover:text-red-500">
            <Search size={20} />
          </button>
          <button className="text-white hover:text-red-500">
            <Heart size={20} />
          </button>
          <button className="text-white hover:text-red-500">
            <User size={20} />
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header;
