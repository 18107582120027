import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft, FaChevronRight, FaTimes, FaImages } from 'react-icons/fa';
import Noimagen from "../../Noimagen.jpg"
const CarGallery = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);

  if (!images || images.length === 0) {
    return (
      <div className="relative rounded-2xl overflow-hidden bg-gray-100 h-[500px] group">
        <img
          src={Noimagen}
          alt="No hay imagen disponible"
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black/50 ">
          <span className="text-white text-lg font-medium">Imagen no disponible</span>
        </div>
      </div>
    );
  }

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const toggleAllImages = () => {
    setShowAllImages(!showAllImages);
  };

  const renderThumbnails = () => {
    const displayImages = showAllImages ? images : images.slice(0, 6);
    const hasMoreImages = images.length > 6;

    return (
      <div className="relative">
        <div className={`grid ${showAllImages ? 'grid-cols-4 md:grid-cols-6' : 'grid-cols-6'} gap-2`}>
          {displayImages.map((image, index) => (
            <div
              key={image.id}
              className={`aspect-square rounded-lg overflow-hidden cursor-pointer relative group ${
                index === currentImageIndex ? 'ring-2 ring-red-500' : ''
              }`}
              onClick={() => setCurrentImageIndex(index)}
            >
              <img
                src={image.url}
                alt={`Vista ${index + 1}`}
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                loading="lazy"
              />
              {index === 5 && !showAllImages && hasMoreImages && (
                <div
                  onClick={toggleAllImages}
                  className="absolute inset-0 bg-black/75 flex flex-col items-center justify-center text-white cursor-pointer hover:bg-black/85 transition-colors"
                >
                  <FaImages className="text-2xl mb-2" />
                  <span className="text-sm font-semibold">+{images.length - 6}</span>
                  <span className="text-xs">Ver más</span>
                </div>
              )}
            </div>
          ))}
        </div>
        {hasMoreImages && (
          <button
            onClick={toggleAllImages}
            className="mt-4 w-full bg-gray-800 hover:bg-gray-700 text-white py-2 rounded-xl flex items-center justify-center gap-2 transition-colors"
          >
            <FaImages className="text-red-500" />
            <span>{showAllImages ? 'Ver menos' : `Ver todas (${images.length})`}</span>
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="relative rounded-2xl overflow-hidden">
        <img
          src={images[currentImageIndex].url}
          alt="Vehículo"
          className="w-full h-[500px] object-cover cursor-pointer"
          onClick={() => setShowFullscreen(true)}
        />
        
        <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/80 to-transparent">
          <div className="flex justify-between items-center">
            <button
              onClick={previousImage}
              className="p-2 bg-white/20 rounded-full hover:bg-white/30 transition-colors"
            >
              <FaChevronLeft className="text-white text-xl" />
            </button>
            <span className="text-white">
              {currentImageIndex + 1} / {images.length}
            </span>
            <button
              onClick={nextImage}
              className="p-2 bg-white/20 rounded-full hover:bg-white/30 transition-colors"
            >
              <FaChevronRight className="text-white text-xl" />
            </button>
          </div>
        </div>
      </div>

      <div className="mt-4">
        {renderThumbnails()}
      </div>

      <AnimatePresence>
        {showFullscreen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black z-50 flex items-center justify-center"
          >
            <button
              onClick={() => setShowFullscreen(false)}
              className="absolute top-4 right-4 p-2 bg-white/20 rounded-full hover:bg-white/30 transition-colors"
            >
              <FaTimes className="text-white text-xl" />
            </button>
            
            <img
              src={images[currentImageIndex].url}
              alt="Vehículo"
              className="max-w-full max-h-[90vh] object-contain"
            />
            
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center space-x-4">
              <button
                onClick={previousImage}
                className="p-2 bg-white/20 rounded-full hover:bg-white/30 transition-colors"
              >
                <FaChevronLeft className="text-white text-xl" />
              </button>
              <span className="text-white">
                {currentImageIndex + 1} / {images.length}
              </span>
              <button
                onClick={nextImage}
                className="p-2 bg-white/20 rounded-full hover:bg-white/30 transition-colors"
              >
                <FaChevronRight className="text-white text-xl" />
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default CarGallery;
