import { FaCar, FaCogs, FaGasPump, FaUsers, FaRoad, FaBolt } from 'react-icons/fa';

const CarSpecs = ({ vehicle }) => {
  const specs = [
    {
      icon: <FaCar className="text-red-500 text-xl" />,
      label: "Puertas",
      value: vehicle.doors || "No especificado"
    },
    {
      icon: <FaGasPump className="text-red-500 text-xl" />,
      label: "Combustible",
      value: vehicle.fuel || "No especificado"
    },
    {
      icon: <FaBolt className="text-red-500 text-xl" />,
      label: "Potencia",
      value: vehicle.power ? `${vehicle.power} CV` : "No especificado"
    },
    {
      icon: <FaUsers className="text-red-500 text-xl" />,
      label: "Plazas",
      value: vehicle.seats || "No especificado"
    },
    {
      icon: <FaCogs className="text-red-500 text-xl" />,
      label: "Cambio",
      value: vehicle.transmission || "No especificado"
    },
    {
      icon: <FaRoad className="text-red-500 text-xl" />,
      label: "Matrícula",
      value: vehicle.licensePlate || "No especificado"
    }
  ];

  return (
    <div className="bg-gray-800/50 rounded-2xl p-6 backdrop-blur-xl border border-gray-700/50">
      <h2 className="text-xl font-bold mb-6">Especificaciones Técnicas</h2>
      
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
        {specs.map((spec, index) => (
          <div key={index} className="flex items-center gap-3">
            {spec.icon}
            <div>
              <p className="text-sm text-gray-400">{spec.label}</p>
              <p className="font-semibold">{spec.value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarSpecs;
