// src/App.js
import React from "react";
import ChatbotButton from "./components/ChatbotButton";
import SearchComponent from "./components/SearchComponent";
import { ModelsProvider } from "./context/ModelsContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DetailVeh from "./components/DetailVeh/DetailVeh";
import Main from "./components/Main/Main";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/veh/:id" element={<DetailVeh />} />
      </Routes>
    </Router>
  );
}

export default App;
