import { FaCalculator, FaCreditCard, FaPercent } from 'react-icons/fa';

const FinancingSection = ({ pricing }) => {
  if (!pricing || !pricing.financing) return null;

  const { financingPrice, installments, installmentAmount, tae, tin } = pricing.financing;

  return (
    <div className="bg-gray-800/50 rounded-2xl p-6 backdrop-blur-xl border border-gray-700/50">
      <h2 className="text-xl font-bold mb-6">Financiación</h2>
      
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <FaCalculator className="text-red-500 text-xl" />
            <div>
              <p className="text-sm text-gray-400">Precio financiado</p>
              <p className="font-semibold text-xl">€{financingPrice?.toLocaleString()}</p>
            </div>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-400">Precio al contado</p>
            <p className="font-semibold text-xl">€{pricing.price.toLocaleString()}</p>
          </div>
        </div>

        {installments > 0 && installmentAmount && (
          <div className="flex items-center gap-3">
            <FaCreditCard className="text-red-500 text-xl" />
            <div>
              <p className="text-sm text-gray-400">Cuotas</p>
              <p className="font-semibold">
                {installments} cuotas de €{installmentAmount.toLocaleString()}/mes
              </p>
            </div>
          </div>
        )}

        {(tae || tin) && (
          <div className="flex items-center gap-3">
            <FaPercent className="text-red-500 text-xl" />
            <div>
              <p className="text-sm text-gray-400">Intereses</p>
              <p className="font-semibold">
                {tae && `TAE: ${tae}%`} {tin && `TIN: ${tin}%`}
              </p>
            </div>
          </div>
        )}

        <button className="w-full bg-red-500 hover:bg-red-600 text-white py-3 px-6 rounded-xl transition-colors">
          Solicitar financiación
        </button>

        <p className="text-xs text-gray-400 text-center">
          *Financiación sujeta a aprobación por parte de la entidad financiera
        </p>
      </div>
    </div>
  );
};

export default FinancingSection;
