import { useState, useEffect } from 'react';
import { FaCar, FaImage } from 'react-icons/fa';
import { getDealcarStock } from '../../services/dealcarService';
import { useNavigate } from 'react-router-dom';
import Noimagen from "../../Noimagen.jpg";

const SimilarVehicles = ({ make, model, currentVehicleId }) => {
  const [similarVehicles, setSimilarVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSimilarVehicles = async () => {
      try {
        setIsLoading(true);
        const response = await getDealcarStock();
        let vehicles = [];

        if (response && response.vehicles) {
          vehicles = response.vehicles;
        } else if (response && response.data && response.data.vehicles) {
          vehicles = response.data.vehicles;
        } else if (Array.isArray(response)) {
          vehicles = response;
        }

        // Filtrar vehículos similares (misma marca o modelo)
        const filtered = vehicles
          .filter(v => 
            (v.make.toLowerCase() === make.toLowerCase() || 
             v.model.toLowerCase() === model.toLowerCase()) &&
            v.vehicleId !== currentVehicleId
          )
          .slice(0, 3); // Limitamos a 3 vehículos similares

        setSimilarVehicles(filtered);
      } catch (error) {
        console.error('Error al cargar vehículos similares:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSimilarVehicles();
  }, [make, model, currentVehicleId]);

  if (isLoading) {
    return (
      <div className="bg-gray-800/50 rounded-2xl p-6 backdrop-blur-xl border border-gray-700/50">
        <h2 className="text-xl font-bold mb-6">Vehículos similares</h2>
        <div className="flex items-center justify-center">
          <div className="text-center text-gray-400">
            <FaCar className="text-red-500 text-4xl mx-auto mb-4 animate-pulse" />
            <p>Buscando vehículos similares...</p>
          </div>
        </div>
      </div>
    );
  }

  if (!similarVehicles.length) {
    return (
      <div className="bg-gray-800/50 rounded-2xl p-6 backdrop-blur-xl border border-gray-700/50">
        <h2 className="text-xl font-bold mb-6">Vehículos similares</h2>
        <div className="flex items-center justify-center">
          <div className="text-center text-gray-400">
            <FaCar className="text-red-500 text-4xl mx-auto mb-4" />
            <p>No se encontraron vehículos similares</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-800/50 rounded-2xl p-6 backdrop-blur-xl border border-gray-700/50">
      <h2 className="text-xl font-bold mb-6">Vehículos similares</h2>
      <div className="space-y-4">
        {similarVehicles.map((vehicle) => (
          <div 
            key={vehicle.vehicleId}
            onClick={() => navigate(`/veh/${vehicle.vehicleId}`)}
            className="cursor-pointer group hover:bg-gray-700/50 p-4 rounded-xl transition-all"
          >
            <div className="flex items-center gap-4">
              {vehicle.multimediaList && vehicle.multimediaList.length > 0 ? (
                <img 
                  src={vehicle.multimediaList[0].url} 
                  alt={`${vehicle.make} ${vehicle.model}`}
                  className="w-24 h-24 object-cover rounded-lg"
                  onError={(e) => {
                    e.target.src = Noimagen;
                  }}
                />
              ) : (
                <div className="relative w-24 h-24 rounded-lg overflow-hidden">
                  <img 
                    src={Noimagen}
                    alt="No hay imagen disponible"
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black/50">
                    <span className="text-white text-xs">No disponible</span>
                  </div>
                </div>
              )}
              <div>
                <h3 className="font-semibold group-hover:text-red-500 transition-colors">
                  {vehicle.make || ''} {vehicle.model || ''}
                </h3>
                <p className="text-sm text-gray-400">{vehicle.version || ''}</p>
                <p className="text-red-500 font-bold mt-1">
                  {vehicle.pricing?.price ? 
                    `$${vehicle.pricing.price.toLocaleString('es-ES')}` : 
                    'Precio no disponible'}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimilarVehicles;
