import ChatbotButton from "../ChatbotButton";
import SearchComponent from "../SearchComponent";

const Main = () => {
  return (
    <div className="App">
      {/* Tu buscador de vehículos */}
      <SearchComponent />

      {/* El chatbot */}
      <ChatbotButton />
    </div>
  );
};

export default Main;
