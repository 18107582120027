import { Phone, MessageSquare } from "lucide-react";
import { FaGem, FaWhatsapp } from "react-icons/fa";

const SellerContact = ({ vehicle }) => {
  const handleContactClick = () => {
    if (vehicle && vehicle.dealcarLink) {
      window.open(vehicle.dealcarLink, "_blank");
    }
  };

  const handleWhatsappClick = () => {
    const message = `Hola, estoy interesado en el ${vehicle.make} ${vehicle.model} ${vehicle.version}`;
    const whatsappUrl = `https://wa.me/+34624081566?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="bg-gray-800/50 rounded-2xl border border-gray-700/50 overflow-hidden">
      <div className="bg-red-600 text-white p-4 rounded-t-xl">
        <div className="flex items-center gap-2">
          <Phone size={18} />
          <span className="font-semibold">Contactar con el vendedor</span>
        </div>
      </div>

      <div className="p-6 flex flex-col items-center">
        <div className="w-20 h-20 bg-gray-300 rounded-full mb-2 border-2 border-red-600 flex items-center justify-center relative">
          <FaGem className="text-gray-600 w-12 h-12 absolute" />
        </div>

        <h3 className="text-lg font-bold">Accar Vip</h3>

        <div className="bg-[#1e1e1e] text-red-500 text-xs px-3 py-1 rounded-full mb-4">
          Vendedor verificado
        </div>

        <button
          className="w-full bg-red-600 hover:bg-red-700 text-white py-3 rounded-xl flex items-center justify-center gap-2 mb-3 transition-colors"
          onClick={handleContactClick}
        >
          <MessageSquare size={16} />
          Solicitar información
        </button>

        <button
          className="w-full bg-[#1e1e1e] hover:bg-gray-800 text-white py-3 rounded-xl flex items-center justify-center gap-2 transition-colors"
          onClick={handleWhatsappClick}
        >
          <FaWhatsapp size={16} />
          Enviar mensaje
        </button>

        <div className="mt-4 text-sm text-gray-400 text-center">
          Horario de atención: Lun-Vie 9:00-20:00
        </div>
      </div>
    </div>
  );
};

export default SellerContact;
