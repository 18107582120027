import { FaShieldAlt, FaCar, FaHandshake, FaCheckCircle } from 'react-icons/fa';

const WhyBuyWithUs = () => {
  const benefits = [
    {
      icon: <FaShieldAlt className="text-red-500 text-2xl" />,
      title: "Garantía de calidad",
      description: "Todos nuestros vehículos pasan rigurosas inspecciones"
    },
    {
      icon: <FaCar className="text-red-500 text-2xl" />,
      title: "Prueba el vehículo",
      description: "30 días o 1000km de garantía de devolución"
    },
    {
      icon: <FaHandshake className="text-red-500 text-2xl" />,
      title: "Transparencia total",
      description: "Historial completo del vehículo disponible"
    },
    {
      icon: <FaCheckCircle className="text-red-500 text-2xl" />,
      title: "Servicio profesional",
      description: "Asesoramiento personalizado en todo momento"
    }
  ];

  return (
    <div className="bg-gray-800/50 rounded-2xl p-6 backdrop-blur-xl border border-gray-700/50">
      <h2 className="text-xl font-bold mb-6">¿Por qué comprar con nosotros?</h2>
      
      <div className="space-y-6">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex items-start gap-4">
            {benefit.icon}
            <div>
              <h3 className="font-semibold">{benefit.title}</h3>
              <p className="text-sm text-gray-400">{benefit.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyBuyWithUs;
