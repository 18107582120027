import { CheckCircle } from "lucide-react"

const CarDescription = ({ description }) => {
  if (!description) return null;

  return (
    <div className="bg-gray-800/50 rounded-2xl p-6 backdrop-blur-xl border border-gray-700/50">
      <h2 className="text-xl font-bold mb-4">Descripción</h2>
      <div className="prose prose-invert max-w-none">
        {description.split('\n').map((paragraph, index) => (
          <p key={index} className="text-gray-300 mb-2">
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};

export default CarDescription;
